<template>
	<nav class="navbar nav-color fixed-top">
		<div class="container">
			<div class="d-flex">
				<li
					@click="$router.push({ name: 'reports' }).catch(() => {})"
					class="d-inline"
				>
					<h2
						v-if="$router.currentRoute.name == 'reports'"
						class="pointer"
						style="color: #fff; font-family: Roboto"
					>
						3DONS BANK
					</h2>
					<h2 v-else><b-icon icon="arrow-left" style="color: #fff" /></h2>
				</li>
			</div>
			<div class="d-flex">
				<li
					class="pointer"
					v-if="isAuthenticated"
					style="color: #fff; list-style: none"
				>
					{{ currentUser.name }} 님
				</li>
				<li class="pointer" v-if="isAuthenticated" @click="logout">
					<b-icon class="mt-1" icon="power" style="color: #fff"></b-icon>
				</li>
			</div>
		</div>
	</nav>
</template>
<script>
import { mapGetters } from "vuex"
import { LOGOUT } from "@/store/actions.type"
import { modalErr } from "/src/common/utils"

export default {
	name: "Nav",
	computed: {
		...mapGetters(["isAuthenticated", "currentUser"]),
	},
	methods: {
		logout() {
			this.$store.dispatch(LOGOUT).then(() => {
				window.localStorage.clear()
				this.showMsgBoxOk("로그아웃 되었습니다.")
			})
		},
		showMsgBoxOk(msg) {
			this.$bvModal
				.msgBoxOk(msg)
				.then((value) => {
					this.boxOne = value
					this.$router.push({ name: "login" })
				})
				.catch((err) => {
					modalErr(err)
				})
		},
	},
}
</script>
<style>
h2 {
	margin-bottom: 0;
}
.navbar {
	height: 60px;
	padding-top: 0;
	padding-bottom: 0;
	background-color: #fff;
	z-index: 1000;
}
.fixed-top {
	position: fixed;
	width: 100%;
	top: 0;
}
.b-sidebar {
	width: 40% !important;
	max-width: 300px;
}
</style>
